import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                { path: 'login-unprotected', element: <Login /> },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'verify', element: <VerifyCode /> },
            ],
        },

        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <RoleBasedGuard>
                        <DashboardLayout />
                    </RoleBasedGuard>
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'dashboard', element: <GeneralApp /> },
                {
                    path: 'profile',
                    children: [{ path: 'edit-profile', element: <UserAccount /> }],
                },
                {
                    path: 'user-management',
                    children: [
                        { path: 'roles', element: <Roles /> },
                        { path: 'role/:id/permission', element: <RolePermission /> },
                        { path: 'role/add', element: <RoleOperation /> },
                        { path: 'role/:id/edit', element: <RoleOperation /> },
                        { path: 'role/:id/view', element: <RoleOperation /> },
                        // { path: 'users', element: <Employees /> },
                        // { path: 'user/add', element: <EmployeeOperation /> },
                        // { path: 'user/:id/edit', element: <EmployeeOperation /> },
                        // { path: 'user/:id/view', element: <EmployeeOperation /> },
                        // { path: 'user-approval', element: <UserApproval /> },
                        // { path: 'user-approval/:id/edit', element: <UserApprovalOperation /> },
                    ],
                },
                {
                    path: 'master',
                    children: [
                        { path: 'authors', element: <Authors /> },
                        { path: 'author/add', element: <AuthorOperation /> },
                        { path: 'author/:id/edit', element: <AuthorOperation /> },
                        { path: 'author/:id/view', element: <AuthorOperation /> },
                        { path: 'quotes', element: <Quotes /> },
                        { path: 'quote/add', element: <QuoteOperation /> },
                        { path: 'quote/:id/edit', element: <QuoteOperation /> },
                        { path: 'quote/:id/view', element: <QuoteOperation /> },
                        { path: 'quotes-images', element: <QuotesImages /> },
                        { path: 'quotes-image/add', element: <QuoteImageOperation /> },
                        { path: 'quotes-image/:id/edit', element: <QuoteImageOperation /> },
                        { path: 'quotes-image/:id/view', element: <QuoteImageOperation /> },
                        { path: 'quotes-categories', element: <QuotesCategories /> },
                        { path: 'quotes-category/add', element: <QuotesCategoryOperation /> },
                        { path: 'quotes-category/:id/edit', element: <QuotesCategoryOperation /> },
                        { path: 'quotes-category/:id/view', element: <QuotesCategoryOperation /> },
                        { path: 'quote-of-the-day', element: <QuoteOfTheDay /> },
                        { path: 'quote-of-the-day/add', element: <QuoteOfTheDayOperation /> },
                        { path: 'quote-of-the-day/:id/edit', element: <QuoteOfTheDayOperation /> },
                        { path: 'quote-of-the-day/:id/view', element: <QuoteOfTheDayOperation /> },
                        { path: 'quote-of-the-day-new', element: <QuoteOfTheDayNew /> },
                        { path: 'quote-of-the-day-new/add', element: <QuoteOfTheDayNewOperation /> },
                        { path: 'quote-of-the-day-new/:id/edit', element: <QuoteOfTheDayNewOperation /> },
                        { path: 'quote-of-the-day-new/:id/view', element: <QuoteOfTheDayNewOperation /> },
                        { path: 'notifications', element: <AddCategoriesToQuote /> },
                        { path: 'quotes-category/to-quote/:id/add', element: <AddCategoriesToQuoteOperation /> },
                        { path: 'quotes-category/to-quote/:id/edit', element: <AddCategoriesToQuoteOperation /> },
                        { path: 'app-versions', element: <AppVersions /> },
                        { path: 'app-version/add', element: <AppVersionOperation /> },
                        { path: 'app-version/:id/edit', element: <AppVersionOperation /> },
                        { path: 'app-version/:id/view', element: <AppVersionOperation /> },
                        { path: 'languages', element: <Languages /> },
                        { path: 'language/add', element: <LanguageOperation /> },
                        { path: 'language/:id/edit', element: <LanguageOperation /> },
                        { path: 'language/:id/view', element: <LanguageOperation /> },
                        { path: 'faqs', element: <Faqs /> },
                        { path: 'faq/add', element: <FaqOperation /> },
                        { path: 'faq/:id/edit', element: <FaqOperation /> },
                        { path: 'faq/:id/view', element: <FaqOperation /> },
                    ],
                },
                {
                    path: '/',
                    children: [
                        { path: 'page-content', element: <PageContent /> },
                        { path: 'page-content/add', element: <PageContentOperation /> },
                        { path: 'page-content/:id/edit', element: <PageContentOperation /> },
                        { path: 'page-content/:id/view', element: <PageContentOperation /> },
                    ],
                },
                {
                    path: '/',
                    children: [
                        { path: 'global-settings', element: <GlobalSettings /> },
                        { path: 'global-settings/add', element: <GlobalSettingsOperation /> },
                        { path: 'global-settings/:id/edit', element: <GlobalSettingsOperation /> },
                        { path: 'global-settings/:id/view', element: <GlobalSettingsOperation /> },
                    ],
                },
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'coming-soon', element: <ComingSoon /> },
                { path: 'maintenance', element: <Maintenance /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// User Management
const Roles = Loadable(lazy(() => import('../pages/user-management/Role/Roles')));
const RolePermission = Loadable(lazy(() => import('../pages/user-management/Role/RolePermission')));

// Roles
const RoleOperation = Loadable(lazy(() => import('../pages/user-management/Role/RoleOperation')));

// Employees
const Employees = Loadable(lazy(() => import('../pages/user-management/Employee/Employees')));
const EmployeeOperation = Loadable(lazy(() => import('../pages/user-management/Employee/EmployeeOperation')));

// User Approval
const UserApproval = Loadable(lazy(() => import('../pages/user-management/UserApproval/UserApproval')));
const UserApprovalOperation = Loadable(
    lazy(() => import('../pages/user-management/UserApproval/UserApprovalOperation'))
);

// Profile
const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));

// Master

// Authors
const Authors = Loadable(lazy(() => import('../pages/masters/Author/Authors')));
const AuthorOperation = Loadable(lazy(() => import('../pages/masters/Author/AuthorOperation')));

// Quotes Text
const Quotes = Loadable(lazy(() => import('../pages/masters/Quote/Quotes')));
const QuoteOperation = Loadable(lazy(() => import('../pages/masters/Quote/QuoteOperation')));

// Quotes Images
const QuotesImages = Loadable(lazy(() => import('../pages/masters/QuoteImage/QuotesImages')));
const QuoteImageOperation = Loadable(lazy(() => import('../pages/masters/QuoteImage/QuoteImageOperation')));

// Quotes Categories
const QuotesCategories = Loadable(lazy(() => import('../pages/masters/QuotesCategory/QuotesCategories')));
const QuotesCategoryOperation = Loadable(lazy(() => import('../pages/masters/QuotesCategory/QuotesCategoryOperation')));

// Quote Of The Day
const QuoteOfTheDay = Loadable(lazy(() => import('../pages/masters/QuoteOfTheDay/QuoteOfTheDay')));
const QuoteOfTheDayOperation = Loadable(lazy(() => import('../pages/masters/QuoteOfTheDay/QuoteOfTheDayOperation')));

// Quote Of The Day New
const QuoteOfTheDayNew = Loadable(lazy(() => import('../pages/masters/QuoteOfTheDayNew/QuoteOfTheDayNew')));
const QuoteOfTheDayNewOperation = Loadable(lazy(() => import('../pages/masters/QuoteOfTheDayNew/QuoteOfTheDayNewOperation')));

// Add categories to quote
const AddCategoriesToQuote = Loadable(lazy(() => import('../pages/masters/AddCategoriesToQuote/CategoriesToQoute')));
const AddCategoriesToQuoteOperation = Loadable(
    lazy(() => import('../pages/masters/AddCategoriesToQuote/CategoriesToQuoteOperation'))
);

// App Versions
const AppVersions = Loadable(lazy(() => import('../pages/masters/AppVersion/AppVersions')));
const AppVersionOperation = Loadable(lazy(() => import('../pages/masters/AppVersion/AppVersionOperation')));

// Language
const Languages = Loadable(lazy(() => import('../pages/masters/Language/Languages')));
const LanguageOperation = Loadable(lazy(() => import('../pages/masters/Language/LanguageOperation')));

// FAQs
const Faqs = Loadable(lazy(() => import('../pages/masters/FAQ/FAQs')));
const FaqOperation = Loadable(lazy(() => import('../pages/masters/FAQ/FAQOperation')));

// Page Content
const PageContent = Loadable(lazy(() => import('../pages/masters/PageContent/PageContents')));
const PageContentOperation = Loadable(lazy(() => import('../pages/masters/PageContent/PageContentOperation')));

// Global Settings
const GlobalSettings = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettings')));
const GlobalSettingsOperation = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettingsOperation')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
